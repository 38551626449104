import { client } from "@/services/api.js";

const getPartner = async () => {
  return await client.get("/api/partner/my");
};

const updatePartner = async (data) => {
  return await client.post("/api/partner/update-partner", data);
};

const updatePracticeLogo = async (data) => {
  return await client.post("/api/partner/update-logo", data);
};

const refreshXPMData = async () => {
  return await client.get("/api/partner/practice-manager-import");
};

const requestUpgrade = async () => {
  return await client.get("/api/payments/request-upgrade");
};

const xpmStatus = async () => {
  return await client.get("/api/xpm/status");
};

const xpmDisconnect = async () => {
  return await client.get("/api/xpm/disconnect");
};

export {
  getPartner,
  updatePartner,
  updatePracticeLogo,
  refreshXPMData,
  requestUpgrade,
  xpmStatus,
  xpmDisconnect,
};
