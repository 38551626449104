import { client } from "@/services/api.js";

export const getBatchJobs = async (params) => {
  const flattenedParams = new URLSearchParams(params).toString();
  return await client.get(`/api/sys/queue?${flattenedParams}`);
};

export const updateJobState = async (data) => {
  return await client.post(`/api/sys/jobstate`, { data });
};

export const listPartners = async (params) => {
  const flattenedParams = new URLSearchParams(params).toString();
  return await client.get(`/api/sys/partners?${flattenedParams}`);
};

export const switchTo = async (id) => {
  return await client.get(`/api/sys/switch?id=${id}`);
};

/* Helpers for displaying admin features. */

const isSandbox =
  process.env.VUE_APP_BASE_API_URL === "https://api.pluginaccountant.com"
    ? false
    : true;

const isSysAdmin = (store) => {
  return store.getItem("role").includes("group:sysadmin");
};

const jsonLink = (json) => {
  let body = JSON.stringify(json, null, 2);
  let win = window.open("data:text/json," + encodeURIComponent(body), "_blank");
  win.document.open();
  win.document.write("<html><body><pre>" + body + "</pre></body></html>");
  win.document.close();
  win.focus();
};

export { isSandbox, isSysAdmin, jsonLink };
