import { ref, computed } from "vue";
import { defineStore } from "pinia";
import { getMe, markBannerAsViewed } from "@/services/user";
import { getTinyMce } from "@/services/sysinfo";
import { getPartner } from "@/services/partner";
import { useRouter } from "vue-router";
import SimpleCrypto from "simple-crypto-js";

const SECRET_KEY = "]oN,R1L?r8N4sn{{ccFeS1oGrLDfK'"; //Random keygen
const simpleCrypto = new SimpleCrypto(SECRET_KEY);

export const useClientStore = defineStore(
  "store",
  () => {
    const router = useRouter();

    const client = ref({
      data: {},
      billingInfoUrl: "",
      role: "",
      partner: {},
      tinyMce: "",
    });

    const isAuthenticated = computed(
      () => client.value.data.email !== undefined
    );

    const getItem = (item) => {
      const { role, partner, data, tinyMce } = client.value;

      switch (item) {
        case "role":
          return role ? simpleCrypto.decrypt(role) : [];
        case "plan":
          return partner ? simpleCrypto.decrypt(partner).selectedPackage : null;
        case "tinyMce":
          return tinyMce ? simpleCrypto.decrypt(tinyMce) : null;
        default:
          return data[item];
      }
    };

    const isSysAdmin = computed(() =>
      getItem("role").includes("group:sysadmin")
    );

    const setCheckoutSessionId = async () => {
      client.value.data.checkoutSessionId = await getMe().then(
        (resp) => resp.data.checkoutSessionId
      );
    };

    const decodedPartner = computed(() => {
      if (isAuthenticated.value) {
        return simpleCrypto.decrypt(client.value.partner);
      }
      return null;
    });
    const setPartner = async () => {
      const partnerData = await getPartner();
      if (partnerData) {
        client.value.partner = simpleCrypto.encrypt(
          JSON.stringify(partnerData.data.data.properties)
        );
      } else {
        return undefined;
      }
    };

    const setTinyMce = async () => {
      client.value.tinyMce = await getTinyMce().then((resp) => {
        if (resp.data.tinyMce) {
          return simpleCrypto.encrypt(resp.data.tinyMce);
        }
      });
    };

    const setClient = async () => {
      await setTinyMce();
      await setPartner();

      client.value.data = await getMe().then((resp) => {
        client.value.role = simpleCrypto.encrypt(resp.data?.groups);
        if (!resp.data.welcomeViewed) {
          router.push({ name: "OnboardingWelcome" });
        } else {
          router.push({ name: "Operation ird" });
        }
        return resp.data;
      });
    };

    const updateStoreMe = async () => {
      client.value.data = await getMe().then((resp) => {
        client.value.role = simpleCrypto.encrypt(resp.data?.groups);
        return resp.data;
      });
    };

    const setClientViewedBanner = async (bannerId) => {
      client.value.data = await markBannerAsViewed(bannerId).then(
        (resp) => resp.data
      );
    };

    const updateStoredPartner = (partner) => {
      client.value.partner = simpleCrypto.encrypt(partner);
    };

    return {
      client,
      setClient,
      setClientViewedBanner,
      getItem,
      isAuthenticated,
      setCheckoutSessionId,
      decodedPartner,
      setPartner,
      updateStoredPartner,
      updateStoreMe,
      isSysAdmin,
    };
  },
  {
    persist: {
      storage: localStorage,
      paths: [
        "client.data.firstName",
        "client.data.lastName",
        "client.data.phoneNumber",
        "client.data.email",
        "client.data.closedBanners",
        "client.data.checkoutSessionId",
        "client.data.partnerId",
        "client.data.welcomeViewed",
        "client.billingInfoUrl",
        "client.role",
        "client.partner.selectedPackage",
        "client.partner",
        "client.tinyMce",
      ],
    },
  }
);
