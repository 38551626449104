<template>
  <n-config-provider :theme-overrides="themeOverrides">
    <n-dialog-provider>
      <n-message-provider>
        <router-view v-slot="{ Component, route }">
          <template
            v-if="['OnboardingPage', 'OnboardingWelcome'].includes(route.name)"
          >
            <transition name="fade" mode="out-in">
              <component :is="Component"></component>
            </transition>
          </template>
          <template v-else>
            <div :class="appClass">
              <div class="row">
                <NavMenu />
              </div>
              <div id="main-app" v-if="!hideHeader">
                <div id="pm-0" class="container">
                  <div class="row">
                    <PluginHeader :label="headerLabel" />
                  </div>
                </div>
              </div>
              <div id="main-container" class="container">
                <div class="row">
                  <transition name="fade" mode="out-in">
                    <component :is="Component"></component>
                  </transition>
                </div>
              </div>
            </div>
          </template>
        </router-view>
      </n-message-provider>
    </n-dialog-provider>
  </n-config-provider>
</template>
<script>
export default {
  name: "pluginApp",
};
</script>
<script setup>
import { computed } from "vue";
import { findParentRoute } from "@/utils/helpers";
import { NConfigProvider, NMessageProvider, NDialogProvider } from "naive-ui";
import { useRoute, useRouter } from "vue-router";
import NavMenu from "@/components/Nav/NavMenu.vue";
import PluginHeader from "./components/Header/Header.vue";

const themeOverrides = {
  common: {
    primaryColor: "#197630",
    successColor: "#197630",
    borderRadius: "4px",
    border: "1px solid #dee2e6",
  },
};

const hideHeader = computed(() => {
  const { meta } = useRoute();
  return meta.hideHeader;
});

const appClass = computed(() => ({
  "container-fluid": true,
  "login-page": hideHeader.value,
}));

const headerLabel = computed(() => {
  const router = useRouter();
  return findParentRoute(router.options.routes, router.currentRoute.value.name)
    ?.name;
});
</script>
<style lang="scss">
@import "@/styles/global";
</style>
<style lang="scss" scoped>
.login-page {
  background: url("@/assets/login_bg.svg") no-repeat;
  background-color: #f5f5f5;
  height: 100vh;
  width: 100vw;
  max-width: 100%;
  background-position: calc(100% + 675px) calc(100% + 380px);
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease-out;
}
</style>
